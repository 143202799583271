import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SocialLink } from "../src/components/SocialLinks/sociallinks";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Hi! My name is Fynn. I was born in Germany, raised in the US and now live in Zürich, Switzerland.`}</p>
    <p>{`As a recent ETH graduate specialized in machine learning, I have spent a lot of time performing experiments that help me understand generalization.
Currently, I'm exploring how to apply this knowledge to AI alignment, which has been a long-standing passion of mine.
I love using my exceptional python skills to make tools that make things easier for my collaborators and I.
On Fridays, you can find me tending the bar at Chante Clair, a tango venue in the Niederdorf.  `}</p>
    <p>{`For more information, download my `}<SocialLink identifier="cv" text="CV" mdxType="SocialLink" />{` or just `}<SocialLink identifier="email" text="reach out" mdxType="SocialLink" />{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      