import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Utils/seo"

import "./about.css"

import SomeText from "../../content/about.mdx"
import Image from "../components/Utils/image"

export default function About(props) {
	return (
		<Layout>
			{/* siteTitle specifies that the title will the siteTitle alone */}
			<SEO title="siteTitle" />
			<section className="About">


 <div class="row">
  <div class="photocolumn">
  

<Image fileName="portrait.jpg" className="respimage" width="300px" alt="portrait"/>
  
  </div>
  <div class="textcolumn">
  

<SomeText/>
	
	</div>
	</div> 
			</section>
		
		</Layout>
	)
}
